@import "variables";
@import "header-variables";
@import "footer-variables";
@import "animations";
@import "mixins";

// Body
body {
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
}

.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.pos-fixed {
  position: fixed;
}

.z-ind-1 {
  z-index: 1;
}

.z-ind-2 {
  z-index: 2;
}

.z-ind-3 {
  z-index: 3;
}

*:focus {
  outline: none;
}

.opacity-0 {
  opacity: 0;
}

p {
  font-size: $font-15-size;
  line-height: $font-15-line-height;
}

.container-fluid {
  padding-right: 4rem;
  padding-left: 4rem;
}

// Backgrounds
.bg-black {
  background-color: $black;
}

.bg-blue-light {
  background-color: $blue-light;
}

.bg-blue-dark {
  background-color: $blue-dark;
}

.bg-white-gray {
  background-color: $white-gray;
}

.bg-gray-back {
  background-color: $gray-back;
}

.bg-gray {
  background-color: $gray;
}

.bg-blue {
  background-color: $blue-dark;
}

.bg-red {
  background-color: $red;
}

// Headers
h2 {
  line-height: $font-32-line-height;
}

h3 {
  line-height: $font-20-line-height;
}

h4 {
  line-height: $font-16-line-height;
}

h5 {
  line-height: $font-14-line-height;
}

.line-height-none {
  line-height: 0;
}

h1.line-down,
h2.line-down,
h3.line-down,
h4.line-down,
h5.line-down,
h6.line-down {
  &::after {
    @include line-down;
    border-color: $red;
  }
}

h1.line-down-center,
h2.line-down-center,
h3.line-down-center,
h4.line-down-center,
h5.line-down-center,
h6.line-down-center {
  font-family: $font-family-vinyl !important;
  font-weight: $font-weight-regular !important;
  text-transform: uppercase;

  &::after {
    @include line-down-center;
    border-color: $red;
  }
}

h1.big {
  font-size: $font-44-size;
  line-height: $font-44-line-height;
  text-transform: uppercase;
  font-family: $font-family-vinyl !important;
}

h4.line-right {
  font-family: $font-family-raleway;
  text-transform: uppercase;

  span {
    position: relative;
    padding-right: 1rem;
    background-color: $white;
  }

  &::before {
    @include line-right;
  }
}

.overlay-element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
}

.scroll-y {
  overflow-y: scroll;
  height: 100%;
}

.h-1060px {
  height: 40rem;
}

.h-480px {
  height: 30rem;
}

.h-256px {
  height: 20rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

// Extras

.border-big {
  border: solid 2px $black;
}

.border-black {
  border: solid 1px $black;
}

.border-big-white {
  border: solid 2px $white;
}

.border-red {
  border: 1px solid $red;
}

.border-red-bold {
  border: 4px solid $red;
}

.border-bottom-red-bold {
  border-bottom: 4px solid $red;
}

.border-left-red-bold {
  border-left: 4px solid $red;
}

.border-bottom-red-normal {
  border-bottom: 1px solid $red;
}

.border-bottom-red {
  border-bottom: 1px solid $red;
}

.red-on-hover {
  &:hover {
    &,
    .all-link {
      color: $red;
    }
  }
}

.badge-red {
  display: inline-block;
  padding: 4px 12px;
  margin: 0 8px 0 0;
  background-color: $red;
  border-radius: 20px;
  color: $white;
  font-family: $font-family-open-sans;
  font-weight: $font-weight-roman;
  font-size: $font-13-size;
  line-height: $font-13-line-height;

  &.badge-blue {
    background-color: $blue-dark;
  }

  &.badge-absolute {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.badge-image-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
}

.logo {
  height: 2rem;
}

.subtitle {
  margin-bottom: 1rem;
  font-size: $font-14-size;
  line-height: $font-14-line-height;
  color: $blue-light;
}

.sub-subtitle {
  margin-bottom: 0;
  font-size: $font-11-size;
  line-height: $font-11-line-height;
  color: $blue-light;
  text-transform: uppercase;
}

.font-8-size {
  font-size: $font-8-size;
}

.font-10-size {
  font-size: $font-10-size;
}

.font-11-size {
  font-size: $font-11-size;
}

.font-12-size {
  font-size: $font-12-size;
}

.font-13-size {
  font-size: $font-13-size;
  p {
    font-size: $font-13-size;
  }
  span {
    font-size: $font-13-size;
  }
  div {
    font-size: $font-13-size;
  }
}

.font-14-size {
  font-size: $font-14-size;
}

.font-15-size {
  font-size: $font-15-size;
}

.font-16-size {
  font-size: $font-16-size;
}

.font-18-size {
  font-size: $font-18-size;
}

.font-19-size {
  font-size: $font-19-size;
}

.font-20-size {
  font-size: $font-20-size;
}

.font-22-size {
  font-size: $font-22-size;
}

.font-24-size {
  font-size: $font-24-size;
}

.font-34-size {
  font-size: $font-34-size;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-none {
  text-transform: none;
}

.text-underline {
  text-decoration: underline;
}

.blue-light-color {
  color: $blue-light;
}

.ng-fa-icon.blue-light-color {
  color: $blue-light;
}

.font-open-sans {
  font-family: $font-family-open-sans;
}

.font-family-raleway {
  font-family: $font-family-raleway;
}

.italic {
  font-style: italic;
}

.text-description {
  font-family: $font-family-open-sans;
  font-size: $font-13-size;
  line-height: $font-13-line-height;
}

.pic-icon-user-container {
  width: 4.2rem;
  height: 4.2rem;
  img {
    width: 1rem;
    height: 1rem;
  }

}

.pic-icon-user-container-big {
  width: 4.6rem;
  height: 4.6rem;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

hr {
  border-width: 1px;
  border-top-color: $gray;
}

.rounded-top-left {
  border-top-left-radius: $border-radius;
}
.rounded-top-right {
  border-top-right-radius: $border-radius;
}
.rounded-bottom-left {
  border-bottom-left-radius: $border-radius;
}
.rounded-bottom-right {
  border-bottom-right-radius: $border-radius;
}

.cursor {
  cursor: pointer;
}

.c-red {
  color: $red;
}

.c-yellow {
  @include c-yellow;
}

.c-white {
  @include c-white;
}

.c-gray {
  color: $gray;
}

.c-blue-light {
  color: $blue-light;
}

.c-blue-dark {
  color: $blue-dark;
}

.text-uppercase {
  text-transform: uppercase;
}

.border-right {
  border-right: 1px solid $gray;
}

.border-gray {
  border: 1px solid $gray;
  border-radius: 10px;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-10-left {
  border-radius: 10px 0 0 10px;
}

.rounded-10-right {
  border-radius: 0 10px 10px 0;
}

.rounded-10-top {
  border-radius: 10px 10px 0 0;
}

.rounded-10-bottom {
  border-radius: 0 0 10px 10px;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-ultra-bold {
  font-weight: $font-weight-ultra-bold;
}

.font-weight-semibold {
  font-weight: $font-weight-medium;
}

.font-weight-base {
  font-weight: $font-weight-base;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.router-link {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.hidden-element {
  visibility: hidden;
}

.dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all .2s linear;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background: inherit;
  }
}

// tooltips
.tooltip-container {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted $blue-light;
}

.tooltip-container .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $blue-dark;
  color: $gray;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  //top: 150%;
  left: 50%;
  margin-left: -60px;
  bottom: 100%;
}

.tooltip-container .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: $blue-dark;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
}

// Anchor
a:focus,
a:hover {
  text-decoration: none;
}

.btn {
  padding: 10px 40px;
  font-size: $font-12-size;
  font-weight: $font-weight-bold;
  line-height: $font-12-line-height;
  text-transform: uppercase;
  border-radius: 24px;
}

.btn-red {
  color: $white;
  background-color: $red;
  @include box-shadow(0 0 13px $red-opacity);

  &:hover {
    color: $white;
    background-color: $red-dark;
    @include box-shadow(0 0 20px $red);
  }
}

.btn-link {
  &:hover,
  &:active {
    text-decoration: none;
    color: $red;
    box-shadow: none;
  }
}

.btn-dark {
  color: $white;
  background: $blue-dark;
  @include box-shadow(0 0 13px $blue-dark-opacity);
  border-color: transparent;

  &:hover {
    color: $white;
    background: $blue-dark;
    @include box-shadow(0 0 20px $blue-dark-opacity);
    border-color: transparent;
  }
}

.btn-outline {
  font-size: $font-12-size;
  line-height: $font-12-line-height;
  border: .125rem solid $blue-dark;

  &:hover {
    background-color: $blue-dark;
    color: $white;
  }
}

.btn-play {
  color: $blue-dark;
  .rounded-circle {
    width: 62px;
    height: 62px;
    background-color: $gray;
    box-shadow: 0 0 2px $blue-dark;
  }
  .play-icon {
    font-size: $font-20-size;
  }
}

.background-dark {
  background-color: $blue-dark;
  color: $blue-light;
}

.background-red {
  background-color: $red;
}

.background-white {
  background-color: $white;
}

.background-white-gray {
  background-color: $white-gray;
}

.background-gray {
  background-color: $gray;
}

.background-transparent {
  background-color: $transparent;
}

// Header
header {
  height: $header-height;
  font-family: $font-family-open-sans;
  font-size: $font-13-size;
  color: $header-text-color;

  nav {
    background: $header-background;
  }

  .navbar {
    padding: .28125rem 0;
  }

  .navbar-brand {
    margin-right: 1.625rem;

    @include brand-img;
  }
}

.search-header {
  margin: 0 auto;
  width: 16.1875rem;

  .search-box {
    width: 16.1875rem;
    height: 2.125rem;
    padding-left: 1.074375rem;
    font-family: $font-family-open-sans;
    font-size: $font-11-size;
    font-weight: 400;
    color: $white;
    background: $blue-light;
    border: none;
    border-radius: 1.25rem;

    &::placeholder {
      color: $white;
      opacity: 1;
    }
  }
  .search-box-white {
    width: 16.1875rem;
    height: 2.125rem;
    padding-left: 1.074375rem;
    font-family: $font-family-open-sans;
    font-size: $font-11-size;
    font-weight: 400;
    color: $blue-light;
    background: $white;
    border: 1px solid $gray-dark;
    border-radius: 1.25rem;

    &::placeholder {
      color: $gray;
      opacity: 1;
    }
  }

  .search-button {
    width: 1.446875rem;
    height: 1.446875rem;
    padding: 0;
    margin-left: -2.5rem;
    background: none;
    border: none;
  }
}

// Notification
.notification {
  .nav-link {
    &::after {
      display: none;
    }

    img {
      width: 1.114375rem;
      height: 1.114375rem;
    }

    .badge {
      position: absolute;
      top: .025rem;
      right: .2125rem;
      height: 1rem;
      padding: .125rem .28rem;
      margin: 0;
      font-family: $font-family-open-sans;
      font-size: $font-8-size;
      font-weight: 700;
      border-radius: .625rem;
      color: #fff;
      background: #f3a800;
      border: .125rem solid #202020;
    }
  }

  .dropdown-menu {
    top: 3.02rem;
    left: -10rem;
    width: 22.19625rem;
    padding: 0;
    margin: 0;
    border: 1px solid #dde2ec;
    border-radius: .4rem;
    box-shadow: none;
  }

  .dropdown-item {
    height: 4.671875rem;
    padding: 0 .875rem;
    cursor: pointer;

    &.last-item {
      height: 2.984375rem;
      padding-top: .75rem;

      a {
        display: inline-block;
        width: 100%;
        font-family: $font-family-open-sans;
        font-size: $font-12-size;
        font-weight: 500;
        color: #667589;
        text-transform: uppercase;
      }

      &:hover,
      &:focus {
        background: none;

        a {
          color: $red;
        }
      }
    }

    .message-notification {
      height: 100%;
      padding-top: .78375rem;
      border-bottom: .0625rem solid #dde2ec;
      .pic-container {
        width: 4.2rem;
        height: 4.2rem;
      }
    }
  }

  .message-sender {
    float: left;
    width: 2.84125rem;
    height: 2.84125rem;
    padding: .5rem .75rem;
    margin-right: .72125rem;
    background: #dde2ec;
    border-radius: 1.875rem;
  }

  .message-content {
    float: left;
  }

  .message-header {
    color: #202020;
    margin: 0;
    font-family: $font-family-open-sans;
    font-size: $font-14-size;
    font-weight: 700;
    line-height: 1.25rem;
  }

  .message-preview {
    width: 15rem;
    margin: 0;
    font-family: $font-family-open-sans;
    font-size: $font-13-size;
    font-weight: 400;
    line-height: 1.25rem;
    color: #667589;
    white-space: normal;
  }
}

.notification-single-component-container {
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

// User
.user {
  font-family: $font-family-open-sans;
  font-size: $font-13-size;
  border-right: 1px solid $black;
  border-left: 1px solid $black;

  .nav-pills .show > .nav-link {
    background: none;
  }

  .nav-link {
    font-family: $font-family-open-sans;
    font-size: $font-13-size;
    color: $white;

    &::after {
      display: none;
    }

    span {
      margin-top: .5rem;
      display: inline-block;
    }

    .ng-fa-icon {
      position: relative;
      top: .05rem;
      margin: .5rem;
      color: $white;

      &:last-child {
        display: none;
      }
    }

  }

  li.show {
    .ng-fa-icon {
      display: none;
    }

    .ng-fa-icon:last-child {
      display: inline-block;
    }
  }

  .dropdown-toggle {
    color: $white;

    img {
      width: 2rem;
      height: 2rem;
      padding: .625rem;
      margin-right: .3125rem;
      background: #f5f6f7;
      border-radius: 2rem;
    }

    .user-avatar {
      width: 2rem;
      height: 2rem;
      margin-right: .3125rem;
      vertical-align: middle;
      display: inline-block;
    }
  }

  .dropdown-menu {
    width: 13rem;
    padding: 0;
    margin-top: .425rem;
    overflow: hidden;
    border: 1px solid #dde2ec;
    border-radius: .625rem;

    .dropdown-item {
      padding: 1.4375rem 1.8125rem 0;

      a {
        display: block;
        width: 100%;
        padding-bottom: 1.1875rem;
        font-size: $font-12-size;
        color: #667589;
        text-transform: uppercase;
        border-bottom: 1px solid #dde2ec;

        img {
          width: 1.25rem;
          margin-right: .3125rem;
          margin-top: -.3rem;
        }
      }

      &:last-child {
        a {
          border-bottom: none;
        }
      }

      &:hover {
        background: $gray-back;
      }
    }
  }
}

// Sections
.sections {
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 4.7rem;
  padding: .7125rem 0 .5625rem;
  box-shadow: 1px 0 9px 0 #dededede;
  background-color: $white;
  transition: all .1s linear;

  .section {
    margin-top: -.4rem;
    width: 7rem;
    height: 4.4rem;
    border-left: 1px solid #f5f5f5;
    transition: all .1s linear;

    &:last-child {
      border-right: 1px solid #f5f5f5;
      width: 11rem;
      padding: .5rem 0;

      p {
        line-height: 1rem;
      }

      .section-title {
        margin-bottom: 0;
      }

      a {
        padding: 0 .5rem;
      }

      span {
        top: -.3rem;
        position: relative;
      }
    }

    a {
      display: block;
      text-align: center;

      &.active {
        border-bottom: 4px solid $red;
      }

      &:hover {
        border-bottom: 4px solid $red;

        .section-title {
          color: $red;
        }
      }
    }
  }

  .section-icon {
    width: 2.6rem;
    height: 2.6rem;
    margin-right: auto;
    margin-left: auto;
    transition: all .1s linear;
  }

  .section-title {
    margin: 0 0 .1rem 0;
    font-family: $font-family-open-sans;
    font-size: $font-12-size;
    font-weight: 400;
    line-height: 1.475rem;
    color: $blue-light;
    text-align: center;
    text-transform: uppercase;
    transition: all .1s linear;
  }

  .icon-svg-last-item {
    width: 3rem;
    height: 3rem;
    transition: all .1s linear;

    img {
      width: 1.5rem;
      height: 1.5rem;
      transition: all .1s linear;
    }
  }
  .last-item {
    .circle {
      width: 2.8rem;
      height: 2.8rem;
    }
    .icon {
      width: 3rem;
    }
  }
}

// Important Note
.important-note {
  font-weight: 700;
}

// Card
.card {
  background-color: $transparent;
  border: none;
  border-radius: 10px;

  &:hover {
    .card-red-on-hover {
      .card-title {
        color: $red;
      }
    }
  }

  &.bg-black {
    background-color: $black;

    .card-img-overlay {
      background: $transparent;
    }
  }

  &.card-hover {
    .card-hover-content {
      opacity: 0;
      max-height: 0;
      visibility: hidden;
      transition: all 1s linear;
    }

    .card-img-overlay {
      //background: none;
      transition: background 1s;
    }

    .card-body {
      padding: 25px 0;
    }

    .card-subtitle,
    .card-title,
    .card-text,
    .card-date {
      color: $white;
    }

    .card-text {
      overflow: hidden;
      height: 3rem;
    }

    &:hover {
      .card-hover-content {
        opacity: 1;
        max-height: 500px;
        visibility: visible;
      }

      .card-no-hover-content {
        display: none;
      }

      .card-img-overlay {
        background: $blue-dark-opacity;
      }
      p {
        font-family: $font-family-open-sans;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0 10px;
  }

  .card-img-top,
  .card-img {
    border-radius: 10px;

    &.max-height-image {
      max-height: 258px;
      width: auto !important;
      margin: 0 auto;
    }
  }

  .card-body {
    padding: 20px 10px;
  }

  .card-subtitle {
    padding: 0;
    font-size: $font-11-size;
    font-weight: $font-weight-roman;
    line-height: $font-11-line-height;
    color: $blue-light;
    text-transform: uppercase;

    &.c-yellow {
      @include c-yellow;
    }
  }

  .card-title {
    padding: 0;
    margin-top: 8px;
    font-family: $font-family-raleway;
    font-size: $font-18-size;
    font-weight: $font-weight-bold;
    line-height: $font-18-line-height;
    color: $blue-dark;

    &.c-yellow {
      @include c-yellow;
    }

    &.c-home {
      font-family: $font-family-vinyl;
      text-transform: uppercase;
    }
  }

  .card-text {
    margin-bottom: 11px;
    font-size: $font-14-size;
    line-height: $font-14-line-height;
    color: $blue-dark;
  }

  .card-date {
    font-size: $font-14-size;
    line-height: $font-14-line-height;
    color: $blue-light;

    &.c-white {
      @include c-white;
    }
  }

  .card-badge {
    display: inline-block;
    padding: 5px 30px;
    font-size: $font-12-size;
    line-height: $font-12-line-height;
    color: $white;
    background-color: $blue-dark;
    border-radius: 16px;
  }

  .card-content {
    margin-top: 11px;
    margin-bottom: 11px;
    font-size: $font-14-size;
    line-height: $font-14-line-height;
    color: $blue-light;
  }

  .card-month {
    margin-bottom: 0;
    font-size: $font-12-size;
    line-height: $font-12-line-height;
    color: $red;
  }

  .card-day {
    font-size: $font-52-size;
    line-height: $font-52-line-height;
    color: $blue-dark;
  }

  .card-all-link {
    position: static;
    top: auto;
    float: none;
    margin-bottom: 1rem;
    margin-left: 49px;

    &::after {
      left: 0;
    }
  }

  .card-user {
    color: $white;

    .card-user-img {
      width: 2rem;
      height: 2rem;
      padding: .625rem;
      margin-right: .3125rem;
      background: $white-gray;
      border-radius: 2rem;
    }

    .card-user-title {
      margin-bottom: 0;
      font-size: $font-13-size;
      line-height: $font-13-line-height;
      text-transform: uppercase;
    }

    .card-user-name {
      margin-bottom: 5px;
      font-size: $font-13-size;
      line-height: $font-13-line-height;
    }
  }

  .card-img-overlay {
    padding: 0 20px;
    background: linear-gradient(180deg, $transparent, $blue-dark-opacity);
    border-radius: 10px;

    &.card-img-overlay-blue {
      background: #1d2c4d80;
      transition: background .5s;

      &:hover {
        background: #1d2c4dd4;
      }
    }

    &.card-img-overlay-hover-line {
      padding: 20px;

      .card-body {

        &::after,
        &::before {
          position: absolute;
          top: 30px;
          right: 30px;
          bottom: 30px;
          left: 30px;
          content: "";
          opacity: 0;
          transition: opacity .5s, transform .5s;
        }

        &::after {
          border-right: 1px solid $yellow;
          border-left: 1px solid $yellow;
          transform: scale(1, 0);
        }
        &::before {
          border-top: 1px solid $yellow;
          border-bottom: 1px solid $yellow;
          transform: scale(0, 1);
        }

        .card-date {
          opacity: 0;
          max-height: 0;
          visibility: hidden;
          transition: all .5s;
        }
      }

      &:hover {
        .card-body {
          &::after,
          &::before {
            opacity: 1;
            transform: scale(1);
          }

          .card-date {
            opacity: 1;
            max-height: 500px;
            visibility: visible;
          }

        }
      }
    }

    .card-bottom {
      position: absolute;
      bottom: 0;
      width: calc(100% - 40px);

      .card-subtitle {
        color: $white;

        &.c-yellow {
          @include c-yellow;
        }
      }

      .card-title {
        font-size: $font-24-size;
        line-height: $font-24-line-height;
        color: $white;

        &.c-yellow {
          @include c-yellow;
        }
      }
    }

    .card-top {
      position: absolute;
      top: 0;
      width: calc(100% - 40px);

      .left {
        float: left;
      }

      .right {
        float: right;
      }

    }
  }

  &.card-mini {
    .card-body {
      padding: 5px 10px;
    }
    .card-title {
      font-size: $font-15-size;
      line-height: $font-15-line-height;
    }
  }

  &.card-big {
    .card-title {
      font-size: $font-22-size;
      line-height: $font-22-line-height;
    }
    .card-user-name,
    .card-user-title {
      color: $blue-light;
    }
  }

  &.card-border {
    border: 1px solid $gray;
    border-radius: 10px;
  }

  &.card-welcome {
    .card-title {
      font-size: $font-22-size;
      line-height: $font-22-line-height;
    }
    .card-text {
      font-size: $font-18-size;
      line-height: $font-22-line-height;
    }
  }

  &.card-cigars {
    min-height: 289px;
    font-weight: $font-weight-bold;
    border-radius: 3px;

    .card-cigar-border {
      background: $gray-back;
      border-radius: 9px;
    }

    .card-img {
      margin: auto;
      width: auto;
      max-height: 100%;
      max-width: 90%;
      align-self: center;
    }
    .image-container {
      display: flex;
      align-content: center;
      height: 290px;
      cursor: pointer;
    }
    .card-img-overlay {
      background: none;
    }
    .card-top img {
      width: 65px;
    }
    .button {
      display: block;
      width: 110px;
      height: 28px;
      border: 1px solid #e6e7e8;
      border-radius: 3px;
      &:hover {
        border: 1px solid #888686;
      }
    }
    .circle {
      width: 38px;
      height: 38px;
      border-color: #b6b3b3;
    }
    .ring-outside {
      width: 26px;
      height: 26px;
      border-color: $black;
    }
    .ring-inside {
      font-size: $font-12-size;
      font-weight: $font-weight-bold;
      text-align: center;
    }
    .led {
      display: inline-block;
      width: 11px;
      height: 11px;
      padding: 1px;
      margin: auto;
      text-align: center;
      border-color: #dde2ec;
    }
    .light {
      display: block;
      width: 7px;
      height: 7px;
      margin: auto;
      background-color: $red;
      border: none;
    }
    .long-block {
      position: absolute;
      display: inline-block;
      margin-top: 5px;
    }
    .long-text {
      width: 38px;
      height: 8px;
      font-size: $font-12-size;
      text-align: center;
    }
    .tobacco-icon {
      width: 38px;
      height: 8px;
      border: 1px solid $black;
      border-radius: 15px 0 0 15px;
    }
    .button-text {
      display: block;
      float: right;
      margin-right: 12px;
      font-size: 11px;

    }
    .card-body {
      padding: 20px 15px;
    }
    .card-subtitle {
      font-family: $font-family-open-sans;
      font-size: $font-16-size;
      font-style: normal;
      color: #667589;
      text-transform: none;
    }
    .card-price > div {
      float: left;
      width: 40%;
    }
    .img-card-price {
      float: left;
    }
    .card-price p {
      font-size: $font-18-size;
      line-height: $font-18-line-height;
      color: $blue-light;
    }
    .icon-price {
      width: 14px;
    }
    &.cigar-long-card {
      flex-direction: inherit;
      min-height: 165px;

      .card-title {
        font-size: $font-20-size;
      }
      .card-subtitle,
      .card-price {
        font-size: $font-18-size;
      }

      .card-top {
        position: absolute;
        top: 0;
        z-index: 1;
      }
      .icon-price {
        float: left;
        width: 14px;
      }
      .image-container {
        height: 235px;
      }
    }
  }
}

.event-card-container {
  .card .card-body {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.cigar-detail {
  color: $blue-light;

  .card-cigar-border {
    background: $gray-back;
    border-radius: 9px;
  }

  h1 {
    font-family: $font-family-raleway;
    font-weight: $font-weight-bold;
    color: $blue-dark;
    font-size: $font-24-size;
  }
  h2 {
    font-family: $font-family-open-sans;
    font-weight: $font-weight-light;
    color: $blue-light;
    font-size: $font-20-size;
  }
  h5 {
    text-transform: uppercase;
    font-family: $font-family-open-sans;
    font-weight: $font-weight-roman;
    color: $blue-light;
    font-size: $font-11-size;
    margin-bottom: 8px;
  }
  h4 {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: $font-14-size;
    line-height: $font-14-line-height;
    color: $blue-dark;
  }
  .text-information {
    font-family: $font-family-open-sans;
    font-size: $font-18-size;
    font-weight: $font-weight-roman;
    color: $blue-dark;
  }
  .long-text {
    font-family: $font-family-open-sans;
    font-size: $font-14-size;
    line-height: $font-14-line-height;
  }
  .icon-cigar {
    min-height: 9px;
    border: 2px solid $blue-dark;
    border-radius: 5px 0 0 5px;
  }
  .icon-circle-strengh {
    width: 18px;
    height: 18px;
    display: inline-block;
  }
  .circle-black {
    background-color: $blue-dark;
  }
  .small-text {
    font-size: $font-13-size;
    color: $blue-dark;
  }
  .medium-text {
    font-size: $font-16-size;
    color: $blue-dark;
  }
  .button-container {
    .icon {
      width: 35px;
      height: 35px;
      border-radius: 3px;
    }
    .printer-icon {
      background-color: $blue-light;
    }
    .send-icon {
      border: 1px solid $blue-light;
    }
    .row-separator {
      margin-top: 10px;
    }
  }
}

.cigar-search-container {
  .arrange-button {
    width: 1rem;
    height: 1rem;
  }
}

// Category list
.categories-list {
  padding: 20px 20px 20px 30px;
  background-color: $blue-dark;
  border-radius: 10px;

  h5 {
    color: $white;
    text-transform: uppercase;

    &::after {
      @include line-down;
      border-color: $red;
    }
  }

  a {
    padding: 5px 10px;
    font-size: $font-14-size;
    font-weight: $font-weight-roman;
    line-height: $font-14-line-height;
    color: $white;
  }
}

// Carousel
.carousel {
  .carousel-indicators {
    li {
      width: 1rem;
      height: 1rem;
      background-color: $transparent;
      border: 1px solid $gray;
      border-radius: 1rem;

      &.active {
        background-color: $red;
      }
    }
  }

  .carousel-arrows {
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 15;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    img {
      cursor: pointer;
    }
  }

  .card {
    .card-img-overlay {
      background: none;

      .card-body {
        padding: 2rem 4rem;

        .card-subtitle {
          color: $white;
        }

        .card-title {
          font-size: $font-44-size;
          line-height: $font-44-line-height;
          color: $white;
        }

        .card-text {
          color: $white;
        }
      }
    }
  }
}

// Breadcrumb list
.breadcrumb-list {
  padding: 5px 0 7px;

  a {
    display: inline-block;
    font-size: $font-12-size;
    line-height: $font-12-line-height;
    color: $blue-dark;

    &::after {
      margin: 0 5px;
      color: $blue-dark;
      content: "▸";
    }
    &:hover {
      //text-decoration: underline;
    }
  }
  p {
    display: inline-block;
    margin-bottom: 0;
    font-size: $font-12-size;
    font-weight: $font-weight-bold;
    line-height: $font-12-line-height;
    color: $blue-dark;
  }
}

// All link
.all-link {
  position: relative;
  top: -35px;
  float: right;
  margin-bottom: -20px;
  font-family: $font-family-roboto;
  font-size: $font-12-size;
  font-weight: $font-weight-medium;
  line-height: $font-12-line-height;
  text-transform: uppercase;

  &.all-link-left {
    position: inherit;
    top: 0;
    float: none;
    margin-top: -8px;
    margin-bottom: 0;
    margin-left: 49px;
  }

  &::after {
    position: relative;
    top: -13px;
    left: -49px;
    display: block;
    width: 36px;
    content: " ";
    border-bottom: 2px solid $red;
  }

  &:hover {
    color: $red;
  }
}

//  Fa icons
.ng-fa-icon {
  margin-right: 4px;
  color: $blue-dark;
}

// Icons
.icon-svg {
  display: flex;
  margin: 0 auto;
  width: 4.5rem;
  height: 4.5rem;
  background-color: $white-gray;
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.icon-svg-big {
    width: 6rem;
    height: 6rem;

    img {
      width: 4rem;
      height: 4rem;
    }
  }

  &.icon-svg-white {
    background-color: $white;
  }
  &.icon-svg-gray {
    background-color: $gray-back;
  }
}

.user-avatar {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto;

  &.user-avatar-3 {
    width: 3rem;
    height: 3rem;
  }
}

.user-avatar-holder {
  width: 2rem;
  height: 2rem;
  padding: .625rem;
  margin-right: .3125rem;
  background: $white-gray;
  border-radius: 2rem;
}

// Go to section
.go-to-section {
  .card {
    .card-title {
      margin-bottom: 0;
      font-size: $font-20-size;
      line-height: $font-20-line-height;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  img {
    max-height: 100px;
  }
}

// Filters
.filters {
  h5 {
    font-family: $font-family-open-sans;
  }
  .filter-title {
    font-size: $font-14-size;
    font-weight: $font-weight-roman;
    line-height: $font-14-line-height;

    img {
      height: 40px;
      margin-right: 20px;
    }
  }
  .filter-value {
    display: block;
    margin-bottom: 0;
    font-size: $font-13-size;
    line-height: $font-13-line-height;

    &.active {
      font-weight: $font-weight-bold;
    }
  }
  .ng5-slider {
    margin-top: 20px;

    .ng5-slider-bar {
      background-color: $gray;
    }
    .ng5-slider-selection {
      background-color: $blue-dark;
    }
    .ng5-slider-pointer {
      top: -6px;
      width: 16px;
      height: 16px;
      background-color: $white;
      border: 1px solid $blue-dark;

      &::after {
        content: none;
      }
    }
  }

  &.filters-border {
    border: 1px solid $gray;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .filter-title {
      padding: .5rem 0;

      a {
        display: block;
        width: 100%;
        border-right: 1px solid $gray;
      }

      &.active {
        border-bottom: 4px solid $red;
      }

      &.no-border {
        a {
          border-right: none;
        }
      }
    }
  }

  .filter-content {
    visibility: visible;
    opacity: 1;
    transition: all .1s linear;

    &.close-content {
      margin-bottom: -20px;
      visibility: hidden;
      max-height: 0;
      opacity: 0;
    }
  }

  .ng-fa-icon {
    margin-top: -38px;
    float: right;
    font-size: $font-11-size;
    transition: transform .1s linear;

    &.close-icon {
      transform: rotate(45deg);
    }
  }
}

// Call to action
.call-to-action {
  background-color: $blue-dark;
  border-radius: 10px;

  h3 {
    margin-bottom: 1rem;
    font-size: $font-24-size;
    line-height: $font-24-line-height;
    color: $white;
  }

  p {
    font-size: $font-13-size;
    line-height: $font-13-line-height;
    color: $white;
  }
}

// Footer
footer {
  color: $footer-text-color;
  background: $footer-background;

  .footer-top {
    height: auto;
    padding-top: 5.625rem;
  }

  .footer-bottom {
    padding-top: 1.25rem;
    border-top: .0625px solid $blue-dark;
  }

  .footer-info {
    img {
      margin-top: .625rem;
      margin-bottom: .625rem;
    }
  }

  .copy {
    padding-top: 1rem;
    padding-bottom: 1.25rem;
    padding-left: .845rem;
    margin: 0;
    font-family: $font-family-open-sans;
    font-size: $font-13-size;
    color: $blue-light;
  }

  .legal {
    margin: .75rem 0 0;

    > a {
      padding-left: 1.75rem;
      font-family: $font-family-open-sans;
      font-size: $font-12-size;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .footer-brand {
    img {
      width: 5.17875rem;
      height: 2.324375rem;
    }
  }

  h2::after {
    @include line-down;
    border-color: $red;
  }

  h2 {
    margin: 0;
    font-family: $font-family-open-sans;
    font-size: $font-12-size;
    font-weight: 700;
    color: $white-gray;
    text-transform: uppercase;
  }

  ul {
    padding: 0;
    margin-top: .88125rem;
    margin-bottom: 2rem;
    font-family: $font-family-open-sans;
    font-size: $font-12-size;
    line-height: 2.5rem;
    color: $white-gray;
    list-style-type: none;
  }

  a {
    color: $white-gray;
    opacity: .8;
    text-decoration: none;

    &:hover {
      color: $white-gray;
      opacity: 1;
    }

  }
}

.circle {
  // border: solid 1px #1d2c4d;
  border: solid 1px #202020;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  .circle-inside {
    width: 70%;
    height: 70%;
    border-radius: 50%;
  }
}

.pointer {
  cursor: pointer;
}

.separator-row-20 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.tobacconinst-button-container {
  margin: 0 0 0 auto;
  max-width: 7.5rem;
  font-size: $font-12-size;
  border-color: $blue-light;
  border-radius: 3px;

  &:hover {
    border-color: $blue-dark;
  }
  .tobacconist-button {
    font-size: $font-11-size;
    border: solid 1px $blue-light;
  }

  .circle.circle-outside {
    width: 1rem;
    height: 1rem;
  }

  .circle.circle-inside {
    width: .6rem;
    height: .6rem;
    border: none;
  }

}

// Gallery image
.gallery-container {
  .image-container {
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .thumbnail-container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      height: 100px;
    }

    .image-thumbnail {
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }
  .brand-image {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

// Calendar
.calendar-widget {
  &.calendar-widget-input {
    min-width: 300px;
    padding: 10px;
  }
  .ng-fa-icon {
    margin-top: 0;
  }

  .cal-month-view {
    .cal-day-cell {
      min-height: 40px;
      cursor: pointer;

      .cal-day-number {
        display: block;
        float: none;
        width: 100%;
        margin-top: 12px;
        margin-right: 0;
        font-size: $font-12-size;
        font-weight: $font-weight-bold;
        line-height: $font-12-line-height;
        color: $blue-light;
        text-align: center;
        opacity: 1;
      }

      &.cal-weekend {
        .cal-day-number {
          color: $blue-light;
        }
      }
      &.cal-today {
        background-color: $transparent;

        .cal-day-number {
          color: $blue-light;
        }
      }
      &.cal-day-selected {
        background-color: $blue-dark;

        .cal-day-number {
          color: $white;
        }
      }
    }
    .cal-cell-top {
      min-height: 40px;
    }
    .cal-header {
      font-weight: $font-weight-regular;

      .cal-cell {
        font-size: $font-10-size;
        line-height: $font-10-line-height;
        color: $blue-light;
      }
    }

    .cal-cell-row {
      .cal-cell:hover {
        background-color: $transparent;

        .cal-day-number {
          color: $blue-light;
        }
      }

      &:hover {
        background-color: $transparent;
      }
    }
  }

  .btn {
    padding: 5px 10px 5px 12px;
    border: 1px solid $gray;
    border-radius: 7px;
  }

  .month-name {
    padding-top: 7px;
    color: $blue-dark;
    font-size: $font-11-size;
    font-weight: $font-weight-bold;
    line-height: $font-11-line-height;
  }

}

.pagination-container {
  button {
    cursor: pointer;
    display: inline-block;
    font-size: $font-16-size;
    line-height: $font-16-line-height;
    font-family: $font-family-open-sans;
    color: $blue-light;
    background: none;
    border: none;
    outline: none;

    &.page-number {
      padding: 5px 12px;
      font-size: $font-15-size;
      line-height: $font-15-line-height;
    }

    &:hover {
      color: $blue-dark;
    }

    &.active {
      background-color: $blue-dark;
      color: $white;
      border-radius: 15px;

      &:hover {
        color: $white;
      }
    }
    &.disabled {
      color: $gray;
    }
  }

}

.card-highlight-container {
  color: $blue-dark;
  .rounded-circle {
    width: 62px;
    height: 62px;
    background-color: $gray;
  }
  .play-icon {
    font-size: $font-20-size;
  }
  img {
    border-radius: 10px;
  }

}

// Progress bar
.progress-course-component {
  .progress-bar-onfire {
    position: relative;
    overflow: hidden;
    &::before {
      position: relative;
      top: 1.51rem;
      display: block;
      width: 100%;
      content: "";
      border: .0625rem solid $blue-light;
      z-index: 0;
    }

    .circles-container {
      position: relative;
      width: 1500px;

    }
    .text-inside-circle {
      margin-top: 25%;
      font-size: $font-18-size;
      color: $white;
    }

  }
  .arrow {
    height: 1rem;
  }
}

.course-class-container {
  p {
    font-size: $font-14-size;
  }
}

.modal-wrapper {
  overflow: auto;
  position: fixed;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: $blue-dark-opacity;
  z-index: 2000;
  .modal-message-container {
    background-color: $white;
    position: relative;
    .close-element {
      position: absolute;
      right: .7rem;
      top: .625rem;
      margin-right: .5rem;
      cursor: pointer;
      color: $blue-light;
      z-index: 1000;
    }
  }
}

.modal-inscription-container {
  width: 44.25rem;
  .month {
    color: $red;
  }
  .day {
    font-size: $font-52-size;
  }
}

.question-container {

  .circle {
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
  }
  input {
    margin-top: 5px;
  }
}

// Stats

.tabs-container {

  h1 {
    text-transform: none;
    font-weight: $font-weight-bold;
    font-family: $font-family-open-sans;
    font-size: $font-24-size;
  }
  h3 {
    font-family: $font-family-raleway;
    font-weight: $font-weight-base;

  }
  h5 {
    font-size: $font-14-size;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 0;
  }
  .tab-content {
    &.active {
      border-bottom: 4px solid $red;
    }
    &.tab-content-simple {
      h5 {
        color: $blue-light;
      }

      &.active {
        border-bottom: none;

        h5 {
          color: $red;
        }
      }
    }
  }
  .icon {
    width: 7%;
  }
}

.grid-header {
  position: absolute;
  padding: 1rem 1.5rem;
  width: calc(100% - 2rem);
  background: $white;
  z-index: 1;
}
.grid-elements {
  .grid-body {
    padding-top: 50px;
  }
}

//region My Shop Section
.my-shop-container {
  margin-top: 10rem;
  .search-header .search-box {
    background-color: $transparent;
    border: 1px solid $gray;
  }
  .search-header .search-box::placeholder {
    color: $gray;
  }
  .tabs-container {
    border: 1px solid $gray;
  }
}

.overlay-profile-picture {
  position: absolute;
  top: 8rem;
  height: 18rem;
  width: 100%;
  background-color: #f5f6f7;
  z-index: -5;
}

.comercial-component-container {
  .dates-container p {
    display: inline-block;
    margin-right: 2rem;
  }
  .active {
    color: $red;
  }
  .dates-container hr {
    margin: 0;
  }
}

//endregion

//region User Profile Vertical Styles
.user-vertical-profile {
  overflow: hidden;
  .icon {
    height: 2rem;
  }
  .trash-icon {
    position: absolute;
    right: 1rem;
    top: 10px;
    width: 2rem;
    height: 2rem;
    border-radius: .3rem;
    background-color: #dde2ec;
    color: #fff;
  }
  .medal {
    height: 2rem;
  }
  .my-profile-banner {
    background: $red;
    position: absolute;
    left: - 1.7rem;
    top: .59rem;
    padding: .2rem 2rem;
    font-weight: $font-weight-bold;
    transform: rotate(-43deg);
    color: $white;
    margin-top: 10px;
  }
  .phone-data.text-center {
    opacity: .5;
  }
}

//endregion

// region My Profile

//endregion

//region app-button-component
.app-button {
  .led-color-class {
    width: .4rem;
    height: .5rem;
    display: inline-block;
  }
  img {
    width: 1rem;
  }
}

.btn-overlay-container {
  position: absolute;
  width: 100%;
  top: 12rem;
}

.phone-data img.icon {
  height: .6rem;
}

// endregion button component

//region MySettings
.my-settings {
  .account-box {
    height: 25.8rem;
  }
}

//region Custom checkbox

.notification-settings-container {
  .container {
    display: block;
    position: relative;
    padding-left: 2rem;
    padding-bottom: .57rem;
    margin-bottom: .8rem;
    cursor: pointer;
    user-select: none;
  }

  .container.first {
    margin-top: 2.57rem;
  }
  .container.last {
    margin-bottom: 2.57rem;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 1;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    border: 1px solid $blue-light;
    border-radius: 4px;
  }

  /* On mouse-over, add a smooth red background color */
  .container:hover input ~ .checkmark {
    border-color: $black;
  }

  /* Create the square checkmark/indicator (hidden when not checked) */
  .checkmark::after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkmark.checked::after {
    display: block;
  }

  .container input:checked ~ .checkmark::after {
    display: block;
  }

  .container .checkmark::after {
    margin: auto;
    width: .68rem;
    height: .68em;
    // background-color: #ec2552;
    background-color: #f3a800;
    border-radius: 2px;
  }
}

//endregion

//endregion

//region Input Component
.input-component {
  label {
    display: block;
    text-transform: uppercase;
    font-weight: $font-weight-regular;
    font-family: $font-family-open-sans;
    font-size: $font-11-size;
  }
  input {
    padding-left: 4px;
    font-size: $font-14-size;
    line-height: $font-14-line-height;
    width: 100%;
    border: none;
    border-bottom: 1px solid $gray;
    &.is-invalid {
      border-bottom: 1px solid $red;
    }
  }
  input:focus {
    border-bottom: 1px solid $blue-dark;
  }
  input::placeholder {
    color: $gray;
  }
  input.error {
    border-bottom-color: $red;
  }
  input.success {
    border-bottom-color: $blue-dark;
  }
  select {
    border: none;
    option {
      border: none;
      &:hover {
        background-color: $gray;
        color: $blue-dark;
      }
    }
  }
  .span.cal-day-number:hover {
    background-color: $white-gray;
  }
}

.triggerElement {
  padding: 1rem 0;
}

.error-message {
  margin-top: -1rem;
  margin-left: 10px;
  font-size: 13px;
}

.input-icon {
  img {
    z-index: 1;
    width: .8125rem;
    height: .8125rem;
    position: absolute;
    left: 1rem;
    top: 1.9rem;
  }
}

.input-icon + .col input {
  padding: 1.2rem 0 1.2rem 1.2rem;
  ::placeholder {
    padding: 1.2rem 0 1.2rem 1.2rem;
  }
}

.input-icon + .col .input-component-select {
  padding-left: 1.2rem;
  ::placeholder {
    padding: 1.2rem 0 1.2rem 1.2rem;
  }
}

.placeHolder-item {
  color: $gray;
  font-size: $font-14-size;
}

.checkbox-input {
  svg {
    height: 1.2rem;
  }
}

//endregion

//region my-settings-component
.my-settings-component {
  .user-logo-container {
    width: auto;
    background: $white-gray;
    float: left;
    border: 1px solid $blue-light;
    border-radius: 100%;

    img {
      width: 8.7rem;
    }

    .user-avatar {
      width: 8.7rem;
      height: 8.7rem;
    }
  }
  .change-email-button {
    font-size: $font-14-size;
    line-height: $font-14-line-height;
  }
  .agm-map-container-inner {
    border-radius: 10px;
  }

}

//endregion

//region Player Component
img.triangle-play.big {
  width: 2rem;
  height: 2rem;
}

.play-container .player-container {
  width: auto;
  height: auto;
}

.player-container {
  opacity: .8;
  box-shadow: 0 0 20px $blue-dark;
  &:hover {
    opacity: 1;
  }
}

//endregion

.options-points {
  position: absolute;
  top: 10px;
  right: 10px;
}

.options-container > div {
  max-height: 400px;
  overflow-y: scroll;
}

//region Messages
.message-component-container {
  color: $blue-light;
  .friends-container {
    height: 37.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .messages-container {
    height: 26rem;
    .pic-container {
      //width: 2rem;
      //height: 2rem;
    }
  }
  textarea {
    padding: 1rem;
    border: none;
    border-top: 1px solid $red;
  }
}

//endregion

img.default_user_img {
  width: 1.5rem;
  height: 1.5rem;
}

img.default_user_img_small {
  width: 1rem;
  height: 1rem;
}

.play-button-outer:hover .play-button {
  opacity: 1;
}

.preview-presentation-component-container {
  .pic-container-big {
    width: 4.6rem;
    height: 4.6rem;
    img {
      width: 2.5rem;
      height: 2.5rem;
      &.user-avatar {
        width: 4.5rem;
        height: 4.5rem;
      }
    }
  }
  .pic-container-small {
    width: 2.5rem;
    height: 2.5rem;
    img {
      width: 1rem;
      height: 1rem;
    }
  }
  .img-small-container {
    width: 2.5rem;
    height: 2.5rem;
  }
  img {
    width: 1rem;
    height: 1rem;
  }
}

.general-search-component-container {
  .section-title {
    font-size: $font-16-size;
    font-family: $font-family-raleway;
  }
}

.map-container {
  path {
    &:hover {
      fill: $gray;
    }
  }
  .selected {
    fill: $red;
  }
  .selected:hover {
    fill: $red;
  }
  .tooltiptext {
    width: 120px;
    height: 40px;
    background-color: $blue-dark;
    color: $gray;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: fixed;
    z-index: 1;
    bottom: 100%;

  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: $blue-dark;
  }
}

.mystats-container {
  h5 {
    margin: 0;
    font-weight: $font-weight-ultra-bold;
    font-family: $font-family-open-sans;
    font-size: $font-15-size;
    display: inline-block;
  }
  h6 {
    margin: 0;
    font-weight: $font-weight-ultra-bold;
    font-family: $font-family-open-sans;
    font-size: $font-12-size;
    text-transform: uppercase;
    color: $blue-light;

  }
  h4 {
    margin: 0;
    font-weight: $font-weight-bold;
    font-family: $font-family-raleway;
    font-size: $font-20-size;
  }

  .img-container img {
    height: 6rem;
  }

  span.bullet {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    left: -4px;
    top: 7px;
  }
}

.scroll-y-70 {
  height: 70%;
  overflow-y: scroll;
}

.scroll-y-95 {
  height: 95%;
  overflow-y: scroll;
}

.button-col-container {
  width: 2.25rem;
  height: 2.25rem;
  img {
    width: 1rem;
    height: 1rem;
  }
}

.horizontal-card-container {
  .image-container img {
    width: 20rem;
  }
  .quantity-container {
    height: 2rem;
    img {
      width: 1.325rem;
    }
  }
  .setting-container {
    width: 2rem;
    height: 2rem;
    img {
      width: 1.325rem;
    }
  }
}

.icon-text {
  img {
    width: .625rem;
    margin-right: .3rem;
  }
}

.icon-medium-container .icon-text {
  img {
    width: 1rem;
    margin-right: .3rem;
  }
}

.input-select-container {
  .options-container {
    position: fixed;
    z-index: 1;
    bottom: -1rem;
  }
  img {
    width: .625rem;
  }
}

.select-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: $transparent;
  z-index: 1;
}

.hover-c-red :hover {
  color: $red;
}

.bg-gradient-role {
  //background: linear-gradient(180deg, $transparent, $blue-dark-opacity);
  background: rgb(236, 37, 82) linear-gradient(128deg, $red 0%, $blue-dark 100%);
  &::after {
    content: "";
    width: 602px;
    height: 600px;
    position: absolute;
    transform: rotate(-45deg);
    left: -508px;
    top: -291px;
    opacity: .1;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(transparent, #fff);
  }
}

.image-uploader-container {
  width: 43rem;
  height: 30rem;
  .image-preview {
    height: 16.5rem;
    border: 1px dashed $blue-light;
    overflow: hidden;
    position: relative;
    .btn {
      top: calc(50% - 2rem);
      left: calc(50% - 6rem);
      z-index: 1000;
    }
  }
}

.modal-invitation-container {
  height: 30rem;
  width: 42rem;
}

.create-event-first-container {
  width: 42.5rem;
  .input-containers > .row {
    margin-top: 2rem;
  }
}

textarea {
  padding: 1rem;
  resize: none;
  border: none;
  &::placeholder {
    color: $blue-light;
    font-size: $font-14-size;
  }
}

.send-modal-message-container {
  width: 30.6rem;
  height: 24.75rem;
}

.drop-down-element {
  background-color: $white;
}

.pointer-filter-container {
  background: $white;
  .circle {
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid $black;
    &.selected {
      background-color: $black;
    }
  }
  .text-container {
    margin-top: -1rem;
  }
}

.display-arrow {
  width: .625rem;
}

.display-inline {
  display: inline-block;
}

.arrow {
  height: 1rem;
}

.login-register-form {
  background-color: $white;
  width: 24.25rem;
  margin-top: 2rem;
  right: 10%;
  float: right;
  position: relative;

  input {
    width: 100%;
    &.is-invalid {
      border-color: $red;
    }
  }
  .title {
    font-family: $font-family-raleway;
    font-size: $font-20-size;
    font-weight: $font-weight-ultra-bold;
  }
  .form-input-container {
    padding: 1rem;
  }
}

.login-register-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $blue-dark;
    border-left: 8px solid $white-gray;
    transform: skew(-11deg);
    left: 73%;
  }
}

.login-register-form-page {
  position: relative;

  .header {
    position: relative;

    > div {
      display: inline-block;
    }
    .register-button {
      float: right;
      margin-right: 5%;

      &:hover {
        color: $red;
      }
    }
  }
  .credentials {
    width: 44rem;
  }

  .tabacalera-logo {
    position: fixed;
    bottom: 2rem;
    right: 5%;
    z-index: 1;

    img {
      height: 80px;
    }
  }

  .welcome-text {
    float: left;
    width: 30rem;
    margin-top: 10%;
    margin-left: 10%;

    .welcome-bottom {
      position: absolute;
      bottom: 15px;
      z-index: -1;
    }
  }

  .city-container {
    img {
      width: 41rem;
    }
  }

  .logo-container {
    margin-left: 5%;

    img {
      width: 9rem;
    }
  }
}

.icava-header {
  &.user .dropdown-toggle img {
    width: 1.5rem;
    height: 2rem;
    padding: 0;
    margin-right: .3125rem;
    background: none;
  }
  &.user .back-arrow img {
    width: 1rem;
    height: 2rem;
    padding: 0;
    margin-right: .3125rem;
    background: none;
  }
  .dropdown-toggle::after {
    display: none;
  }
}

.search-icava-bar {
  position: absolute;
  z-index: 1;
  background: $white;
  margin: 4px -15px;
}

.border-right-horizontal-filter {
  border-right: 1px solid $gray;
}
