@mixin brand-img {
  img {
    width: 97px;
    height: 43px;
  }
}

@mixin line{
  display: inline-block;
  width: 3.625rem;
  margin-right: 1.1875rem;
  vertical-align: middle;
  content: "";
  border: .0625rem solid $black;
}

@mixin line-down {
  position: relative;
  top: .8rem;
  display: block;
  width: 1.375rem;
  content: "";
  border: .0625rem solid $black;
}

@mixin line-down-center {
  @include line-down;
  margin: 0 auto;
}

@mixin line-right {
  position: relative;
  top: 1.65rem;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  content: "";
  border: .0625rem solid $gray;
}

@mixin c-yellow {
  color: $yellow;
}

@mixin c-white {
  color: $white;
}

