@import "variables";

@media (min-width: 769px) {
  .sections {
    &.sections-hide-icon {
      height: 3rem;

      .section {
        height: 2.4rem;

        .section-title {
          margin-top: -1rem;
          margin-bottom: .5rem;
        }
        .section-icon {
          height: 0;
        }

        &:last-child {
          a {
            margin-top: -.5rem;
          }

          .icon-svg-last-item {
            height: 2rem;
            width: 2rem;
          }

          .section-title {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Min width 1020px
@media (min-width: 1020px) {
  .container {
    max-width: 1000px;
  }
}

// Min width 1120px
@media (min-width: 1120px) {
  .container {
    max-width: 1100px;
  }
}

// Min width 1200px
@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }

  .vertical-filters {
    .filters {
      & > .filter-container:nth-child(1),
      & > .filter-container:nth-child(2),
      & > .filter-container:nth-child(3),
      & > .filter-container:nth-child(4),
      & > .filter-container:nth-child(5),
      & > .filter-container:nth-child(6),
      & > .filter-container:nth-child(7),
      & > .filter-container:nth-child(8) {
        display: none;
      }
    }
  }
}

// Min width 1300px
@media (min-width: 1300px) {
  .container {
    max-width: 1240px;
  }
}

// Min width 1500px
@media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}

// Min width 1700px
@media (min-width: 1700px) {
  .container {
    max-width: 1640px;
  }

  .container-fluid {
    max-width: 1640px;
  }
}

// Max width 1700px
@media (max-width: 1700px) {
  .none {
    font-size: 1rem;
  }
}

// Max width 1500px
@media (max-width: 1500px) {
  .none {
    font-size: 1rem;
  }
}

// Max width 1200px
@media (max-width: 1200px) {
  .login-register-background {
    &::after {
      content: none;
    }
  }

  .login-register-form {
    right: 5%;
  }

  .horizontal-filters {
    .row {
      .col:nth-child(7) {
        display: none;
      }
      .col:nth-child(6) {
        border-right: none;
      }
    }
  }

  .vertical-filters {
    .filters {
      .filter-container:nth-child(1),
      .filter-container:nth-child(2),
      .filter-container:nth-child(3),
      .filter-container:nth-child(4),
      .filter-container:nth-child(5),
      .filter-container:nth-child(6),
      .filter-container:nth-child(7) {
        display: none;
      }
    }
  }
}

// Max width 1100px
@media (max-width: 1100px) {
  .horizontal-filters {
    .row {
      .col:nth-child(6),
      .col:nth-child(5) {
        display: none;
      }
      .col:nth-child(4) {
        border-right: none;
      }
    }
  }

  .vertical-filters {
    .filters {
      .filter-container:nth-child(6),
      .filter-container:nth-child(7) {
        display: block;
      }
    }
  }
  .sections {
    .section {
      width: 5.5rem;
    }
    .section-icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

// Max width 992px
@media (max-width: 992px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  .horizontal-filters {
    .row {
      .col:nth-child(4) {
        display: none;
      }
      .col:nth-child(3) {
        border-right: none;
      }
    }
  }

  .vertical-filters {
    .filters {
      .filter-container:nth-child(5) {
        display: block;
      }
    }
  }
}

// Max width 870px
@media (max-width: 800px) {
  .none {
    font-size: 1rem;
  }
}

// Max width 770px
@media (max-width: 770px) {
  .arrange-button {
    display: none;
  }

  .cigar-search-container {
    .filters {
      position: fixed;
      z-index: 1;
      top: 126px;
      right: 0;
      height: 100%;
      max-width: 100%;
      width: 20rem;
      background: $white;
      border: 1px solid $gray;
      padding: 20px;
      overflow-y: scroll;
      h5 {
        display: none;
      }

      &.hide {
        display: none;
      }

      .filter-content.close-content {
        display: none;
      }
    }

    h3,
    .search-bar {
      display: none;
    }

    .search-pointer,
    .filter-pointer {
      display: block !important;
    }

    .search-pointer {
      .ng-fa-icon {
        display: block;
        color: $white;
        margin: 12px 0;
      }
    }

    .filter-pointer {
      .ng-fa-icon {
        display: block;
        color: $blue-dark;
        margin: 12px 0;
      }
    }

    .search-filter-bar {
      height: 70px;
      margin: -5px -15px;

      .rounded-10 {
        border-radius: 0;
      }
    }
  }

  .sections {
    height: 2.3rem;

    .section {
      width: 2.5rem;
      height: 2.5rem;
    }
    .section-title,
    .section-subtitle {
      display: none;
    }

    .icon-svg-last-item {
      width: 1rem;
      height: 1rem;
    }

    .section:last-child {
      width: auto;
    }
  }

  // Carousel
  .carousel {
    .card {
      .card-img-overlay {
        .card-body {
          .card-title {
            font-size: $font-20-size;
            line-height: $font-20-line-height;
          }
        }
      }
    }
  }
}

// Max width 575px
@media (max-width: 575px) {
  .login-register-form {
    left: 5%;
    width: 90%;
    float: none;
  }

  .login-register-form-page {
    .header {
      text-align: center;

      > div {
        display: block;
        margin: 40px 0;
      }
      .register-button {
        margin: 20px 0;
        display: inline-block;
        float: none;
      }
    }

    .tabacalera-logo {
      position: relative;
      text-align: center;
      margin-top: 2rem;
      bottom: 0;
      right: 0;
    }
  }

  .horizontal-filters {
    .row {
      .col:nth-child(3),
      .col:nth-child(2) {
        display: none;
      }
      .col:nth-child(1) {
        border-right: none;
      }
    }
  }

  .vertical-filters {
    .filters {
      .filter-container:nth-child(3),
      .filter-container:nth-child(4) {
        display: block;
      }
    }
  }

  .search-header {
    width: 10rem;
  }
}

@media only screen and (max-width: 770px) {
  .col-md-9 {
    flex: 100% !important;
    width: 100% !important;
    min-width: 100%;
  }
}
