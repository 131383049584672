// Font family
$font-family-sans-serif: "Open Sans", "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-family-open-sans: "Open Sans", "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-raleway: "Raleway", "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-roboto: "Roboto", "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-family-vinyl: "vinyl", "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

// Colors
$transparent: transparent !default;
$black: #000 !default;
$white: #fff !default;
$white-gray: #f5f6f7 !default;

// $blue-dark: #1d2c4d !default;
// $blue-dark-opacity: #1d2c4dc4 !default;
$blue-light: #667589 !default;

$blue-dark: #202020 !default;
$blue-dark-opacity: #1b1616 !default;

$gray: #dde2ec !default;
// $gray-back: #f8f9fb !default;
$gray-back: #fafafa !default;
$gray-dark: #bfc3cc !default;

// $red: #ec2552 !default;
// $red-opacity: #ec255273 !default;
// $red-dark: #ca1b43 !default;
$red: #f3a800 !default;
$red-opacity: #e1a95772 !default;
$red-dark: #cc8b00 !default;
$yellow: #e1a957 !default;

$primary: $black !default;
$secondary: $white !default;

// Font weight
$font-weight-black: 900 !default;
$font-weight-ultra-bold: 800 !default;
$font-weight-bold: 700 !default;
$font-weight-roman: 600 !default;
$font-weight-medium: 500 !default;
$font-weight-regular: 400 !default;
$font-weight-book: 400 !default;
$font-weight-light: 300 !default;
$font-weight-thin: 200 !default;
$font-weight-ultra-light: 100 !default;

$font-weight-base: $font-weight-medium !default;

// Fonts
$font-52-size: 3.25rem !default;
$font-52-line-height: 3.25rem !default;
$font-44-size: 2.75rem !default;
$font-44-line-height: 3rem !default;
$font-35-size: 2.1875rem !default;
$font-35-line-height: 2.1875rem !default;
$font-34-size: 2.125rem !default;
$font-34-line-height: 2.125rem !default;
$font-33-size: 2.0625rem !default;
$font-33-line-height: 2.0625rem !default;
$font-32-size: 2rem !default;
$font-32-line-height: 2rem !default;
$font-31-size: 1.9375rem !default;
$font-31-line-height: 1.9375rem !default;
$font-30-size: 1.875rem !default;
$font-30-line-height: 1.875rem !default;
$font-29-size: 1.8125rem !default;
$font-29-line-height: 1.8125rem !default;
$font-28-size: 1.75rem !default;
$font-28-line-height: 1.75rem !default;
$font-27-size: 1.6875rem !default;
$font-27-line-height: 1.6875rem !default;
$font-26-size: 1.625rem !default;
$font-26-line-height: 1.625rem !default;
$font-25-size: 1.5625rem !default;
$font-25-line-height: 1.5625rem !default;
$font-24-size: 1.5rem !default;
$font-24-line-height: 2.0625rem !default;
$font-23-size: 1.4375rem !default;
$font-23-line-height: 1.4375rem !default;
$font-22-size: 1.375rem !default;
$font-22-line-height: 2.0625rem !default;
$font-21-size: 1.3125rem !default;
$font-21-line-height: 1.3125rem !default;
$font-20-size: 1.25rem !default;
$font-20-line-height: 1.875rem !default;
$font-19-size: 1.1875rem !default;
$font-19-line-height: 1.1875rem !default;
$font-18-size: 1.125rem !default;
$font-18-line-height: 1.625rem !default;
$font-17-size: 1.0625rem !default;
$font-17-line-height: 1.0625rem !default;
$font-16-size: 1rem !default;
$font-16-line-height: 1.75rem !default;
$font-15-size: .9375rem !default;
$font-15-line-height: 1.4375rem !default;
$font-14-size: .875rem !default;
$font-14-line-height: 1.5rem !default;
$font-13-size: .8125rem !default;
$font-13-line-height: 1.125rem !default;
$font-12-size: .75rem !default;
$font-12-line-height: 1.375rem !default;
$font-11-size: .6875rem !default;
$font-11-line-height: .9375rem !default;
$font-10-size: .625rem !default;
$font-10-line-height: .625rem !default;
$font-8-size: .5rem !default;
$font-8-line-height: .5rem !default;

// Headers
$h1-font-size: $font-35-size !default;
$h2-font-size: $font-32-size !default;
$h3-font-size: $font-20-size !default;
$h4-font-size: $font-16-size !default;
$h5-font-size: $font-14-size !default;
$headings-font-family: $font-family-raleway !default;
$headings-font-weight: $font-weight-bold !default;
$headings-color: $blue-dark !default;
$headings-line-height: $font-35-line-height !default;
$headings-margin-bottom: 1.5rem !default;

// Navbar
$navbar-padding-x: 0 !default;
$navbar-padding-y: 9.1px !default;

$navbar-link-font-weight: $font-weight-medium !default;
$navbar-link-font-size: $font-18-size !default;
$navbar-link-line-height: $font-18-line-height !default;

// Dropdown
$dropdown-border-radius: .6rem !default;

// Jumbotron
$jumbotron-bg: $transparent !default;
$jumbotron-padding: 1rem !default;

// Buttons
$btn-font-weight: $font-weight-light !default;
$btn-border-width: .125rem !default;
// $btn-padding-x: 2rem !default;
$btn-padding-y: .3125rem !default;

// Shadow
$enable-shadows: true !default;

// Gradient
$enable-gradients: true !default;

// Border
$border-radius: .625rem !default;
